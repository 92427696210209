/* ADVANTAGES SECTION */
.hk-advantages-section {
  background: url('/assets/images/empty-sofa-full-view.png') no-repeat center
    bottom / contain $light-gray-color;

  padding-bottom: 110px;

  .hk-advantages-cards {
    display: grid;
    gap: 20px;
    margin-bottom: 20px;
  }

  .hk-card {
    border-radius: 2px;
    padding: 24px 20px;
    margin-bottom: 0;

    .hk-card-header {
      display: flex;
      gap: 16px;
      align-items: center;
      margin-bottom: 12px;

      @media screen and (max-width: 299px) {
        flex-wrap: wrap;
      }

      .icon,
      .title {
        margin: 0;
      }
      .icon {
        flex-shrink: 0;
        img {
          width: 50px;
          height: 50px;
        }
      }

      .title {
        @include typography-heading-h6;
        @include typography-medium;
      }
    }
  }

  @media screen and (min-width: 400px) {
    padding-bottom: 150px;
  }
  @media screen and (min-width: 576px) {
    padding-bottom: 220px;
  }

  @media screen and (min-width: 992px) {
    background: url('/assets/images/empty-sofa-half-view.png') no-repeat center
      bottom / cover $light-gray-color;

    .hk-advantages-cards {
      gap: 24px;
      margin: 0;
    }
    .hk-card {
      padding: 24px;
    }
  }
}
