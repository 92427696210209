.hk-page-footer {
  background-color: $neutral-lightest-color;
  padding: 40px 0px;

  .container {
    display: grid;
    gap: 40px;
  }

  .hk-footer-site-logo {
    margin-bottom: 26px;
  }

  .hk-footer-nav {
    margin-bottom: 28px;
  }

  .hk-footer-nav-items {
    line-height: 21px;
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    gap: 20px;

    .hk-footer-nav-item {
      @include typography-body-regular;
      a {
        padding: 10px 0;
        color: $primary-color;
        cursor: pointer;
        text-decoration: none;

        &:hover {
          color: $nav-link-hover-text-color;
        }
      }

      &.title {
        @include typography-title-xs;
      }
    }
  }

  .hk-footer-addresses {
    @include typography-body-regular;
    margin-top: 40px;
  }

  .copyright {
    @include typography-body-regular;
    color: $dark-black-color;
    line-height: 21px;
  }

  @media screen and (min-width: 992px) {
    padding-top: 80px;
  }
}
