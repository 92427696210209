#mainNavbar {
  .white-element {
    display: none;
  }

  #navbarContent {
    background-color: $neutral-lightest-color;
    padding: 24px 0;
    border-radius: 0 0 2px 2px;
    margin-top: 12px;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.25);
  }

  .navbar-nav {
    --bs-nav-link-padding-y: 10px;
  }

  .dropdown-menu {
    background-color: $neutral-lightest-color;
    padding: 0;
    border: 0;
    border-radius: 2px;

    .dropdown-item {
      @include typography-body-regular;
      color: $primary-color;
      padding: 10px 20px;

      &:hover {
        color: $neutral-dark-color;
        background-color: transparent;
      }
    }
  }

  .dropdown-toggle::after {
    border: 0;
    font: normal normal normal 1em/1 'lineicons';
    content: '\eb11';
    margin-left: 10px;
    vertical-align: middle;
    font-size: inherit;
  }

  .nav-link {
    @include typography-body-regular;
    color: $primary-color;

    &:hover {
      color: $neutral-dark-color;
    }
  }

  .nav-contacts {
    gap: 16px;
    margin-bottom: 16px;

    .nav-contact {
      display: flex;
      gap: 5px;
      align-items: center;
    }
  }

  .nav-cta {
    @include typography-caption-regular;
    background-color: $primary-color;
    border-radius: 2px;
    width: 100%;
    padding: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    &:hover {
      background-color: $nav-link-hover-bg-color;
    }
  }

  &.bg-white {
    background: $white-color;
    color: $primary-color;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.15);
    transition: all 0.2s ease-in-out;
  }

  .hk-navbar-toggler {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  .hk-nav-toggle-icon {
    padding: 5px;
    padding-right: 0;
    .hamburger {
      display: block;
    }

    .cross {
      display: none;
    }

    &.open {
      .hamburger {
        display: none;
      }
      .cross {
        display: block;
      }
    }
  }

  @media screen and (min-width: 992px) {
    height: $main-nav-height-lg;
    .navbar-brand {
      margin-top: -3px;
    }

    .navbar-nav {
      align-items: center;
    }

    #navbarContent {
      background-color: transparent;
      box-shadow: none;
      padding: 0;
      margin-top: 0;

      &.collapse.mobile {
        display: none !important;
      }
    }

    .dropdown-menu {
      padding: 14px 0px;

      .dropdown-item {
        padding-left: 32px;
        padding-right: 32px;
      }
    }

    .nav-contacts {
      gap: 6px;
      margin-bottom: 0;
    }

    .nav-cta {
      @include typography-caption-regular;
      width: 166px;
    }
  }

  @media screen and (min-width: 1200px) {
    .nav-link {
      --bs-navbar-nav-link-padding-x: 12px;
    }
  }
}

#mainNavbar.navbar-white {
  .white-element {
    display: block;
  }
  .dark-element {
    display: none;
  }
  .hk-navbar-toggler {
    color: $white-color;

    .hk-nav-toggle-icon svg {
      .menu-paths {
        stroke: #ffffff;
      }
      .fill {
        fill: #ffffff;
      }
    }
  }

  .navbar-collapse.mobile {
    .white-element {
      display: none;
    }
    .dark-element {
      display: block;
    }
  }

  .navbar-collapse.desktop {
    .nav-link {
      color: $white-color;

      &:hover {
        color: $light-gray-color;
      }
    }
  }
}
