.teilverkauf-landing {
  .navbar-collapse.desktop {
    justify-content: flex-end;
  }
  .navbar-nav {
    display: none;
  }

  /* HERO AREA */
  .hk-teilverkauf-hero {
    padding-bottom: 480px;
    .hk-teilverkauf-hero-content {
      background-color: $white-color;
      padding: 24px 20px;

      .imgae {
        margin-bottom: 20px;
        max-width: 190px;
        img {
          width: 100%;
        }
      }
      .content {
        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            display: flex;
            align-items: flex-start;
            gap: 8px;

            &:not(:last-child) {
              margin-bottom: 8px;
            }

            i {
              transform: rotate(-5deg);
              font-size: 18px;
              font-weight: bold;
              line-height: 1.5;
            }
          }
        }
      }
    }

    @media screen and (min-width: 992px) {
      background: url('/assets/images/home-how-to-use-bg.jpg') center 30% /
        cover no-repeat;
      padding-top: calc(26px + $main-nav-height-lg);
      padding-bottom: 85px;

      .hk-teilverkauf-hero-content {
        padding: 40px 60px;
        max-width: min(612px, 100%);

        .image {
          margin-bottom: 32px;
          max-width: 143px;
        }

        .title {
          font-size: 39px;
          line-height: 46px;
          margin-bottom: 20px;
        }
        .content {
          margin-bottom: 32px;

          ul li:not(:last-child) {
            margin-bottom: 4px;
          }
        }
      }

      .cta {
        @include typography-heading-h6;
      }
    }
  }

  /* WHY CHOOSE SECTION */
  .hk-why-choose-section {
    @media screen and (min-width: 768px) {
      .hk-card {
        padding-bottom: 32px;
      }
    }
  }

  /* OUR PRODUCTS SECTION */
  .hk-our-products-section {
    .hk-section-title {
      color: $white-color;
    }

    .hk-product-image {
      max-width: min(75%, 251px);
      margin-bottom: 20px;

      @media screen and (min-width: 992px) {
        max-width: min(75%, 313px);
        margin-bottom: 93px;
      }
    }

    @media screen and (min-width: 992px) {
      .hk-card {
        .content {
          margin-bottom: 20px;
        }

        .hk-checklist-container {
          margin-bottom: 40px;
        }
      }
    }

    @media screen and (min-width: 768px) {
      .hk-card {
        .hk-action-btns {
          flex-direction: row;
          gap: 24px;

          .hk-btn {
            flex: 1 1 auto;
          }
        }
      }
    }
  }
}

/* TEILVERKAUF ADVANTAGE SECTION */
.hk-teilverkauf-advantages {
  .hk-advantages {
    --bs-gutter-y: 2.5rem;
  }

  .hk-advantage {
    display: flex;
    gap: 24px;

    .icon {
      width: 50px;
      height: 50px;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .content {
      .title {
        @include typography-heading-h5;
        @include typography-medium;
        color: $primary-color;
        margin-bottom: 8px;
      }

      .description {
        @include typography-body-regular;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .hk-advantages {
      --bs-gutter-x: 3rem;
      --bs-gutter-y: 3.25rem;
    }
    .hk-advantage {
      .content {
        .title {
          @include typography-heading-h4;
          @include typography-medium;
          margin-bottom: 12px;
        }
      }
    }
  }

  @media screen and (min-width: 992px) {
    .hk-advantages {
      --bs-gutter-x: 7rem;

      .hk-advantage {
        .icon {
          width: 70px;
          height: 70px;
        }
      }
    }
  }
}
