#testimonial-slider.carousel {
  display: block;
  background-color: $neutral-light-color;
  padding-bottom: 28px;

  .hk-carousel-prev,
  .hk-carousel-next {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .lni {
      font-size: 28px;
      color: $dark-gray-color;
    }
  }

  .carousel-inner {
    width: 100%;
    position: relative;
  }

  .testimonial-container {
    width: 100%;
    display: block;

    .testimonial-media {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .testimonial-content {
      font-family: Lora, system-ui, serif;
      background-color: $neutral-light-color;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 28px 20px 20px;
      width: 100%;

      .quote {
        width: 32px;
      }

      .text {
        color: $light-black-color;
        font-size: 18px;
        font-weight: 500;
        font-style: italic;
        line-height: 24px;
        margin-bottom: 0px;
      }
      .author {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0;
      }
    }
  }

  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    padding-bottom: 0;

    .carousel-inner {
      width: min(826px, 80%);
    }

    .hk-carousel-prev {
      left: 40px;
    }
    .hk-carousel-next {
      right: 40px;
    }

    .testimonial-container {
      display: flex;
      align-items: center;
      justify-content: center;

      .testimonial-media {
        width: 250px;
      }

      .testimonial-content {
        width: calc(100% - 225px);
        flex-shrink: 0;
        position: relative;
        padding: 28px 23px 17px;
        margin-left: -25px;

        .quote {
          position: absolute;
          top: -12px;
          left: 18px;
        }

        .text {
          margin-bottom: 20px;
        }
      }
    }
  }
}
