/* HERO AREA */
.hk-teilverkauf-hero {
  background: url('/assets/images/teilverkauf-hero-image-vertical.jpg') 50%
    bottom / cover no-repeat;
  padding-bottom: 300px;

  .hk-teilverkauf-hero-content {
    background-color: transparent;
    padding: 0px;

    .title {
      font-weight: 400;
      font-size: 34px;
      line-height: 41px;
      margin-bottom: 12px;
      color: $primary-color;
      word-break: break-word;

      .bold {
        font-weight: 700;
        &::after,
        &::before {
          content: ' ';
        }
      }
      .secon-line {
        letter-spacing: -1.2px;
      }
    }

    .content {
      @include typography-heading-h6;
      margin-bottom: 28px;
    }

    .cta {
      @include typography-body-regular;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 52px;
      width: 100%;
      padding: 16px;
      color: $white-color;
      text-decoration: none;
      background-color: $primary-color;
      margin-bottom: 20px;
      cursor: pointer;

      & > div {
        gap: 16px;
      }

      &:hover {
        background-color: $nav-link-hover-bg-color;
        .arrow {
          transform: translateX(10px);
        }
      }

      .arrow {
        transition: all 0.2s ease-in-out;
      }
    }
  }

  @media screen and (min-width: 500px) {
    padding-bottom: 400px;
  }

  @media screen and (min-width: 700px) {
    padding-bottom: 500px;
  }

  @media screen and (min-width: 800px) {
    padding-bottom: 700px;
  }

  @media screen and (min-width: 992px) {
    background: url('/assets/images/home-how-to-use-bg.jpg') right top / cover
      no-repeat;
    padding-bottom: 158px;

    .hk-teilverkauf-hero-content {
      max-width: min(484px, 100%);
      padding: 0;

      .title {
        font-size: 39px;
        line-height: 46px;

        .second-line {
          letter-spacing: -0.03em;
        }
      }
      .content {
        margin-bottom: 32px;
      }

      .cta {
        @include typography-heading-h6;
        height: 59px;
      }
    }
  }

  @media screen and (min-width: 1600px) {
    background-position: right 30%;
  }
}

/* WHY CHOOSE HEIMKAPITAL SECTION */
.product-teilverkauf {
  .hk-why-choose-section {
    background-color: $primary-color;

    .hk-section-title .teilverkauf-logo {
      max-width: 214px;
    }

    @media screen and (min-width: 768px) {
      .hk-section-title .teilverkauf-logo {
        max-width: 284px;
      }

      .hk-card {
        padding-bottom: 32px;
      }
    }
  }
}

/* CALCULATOR SECTION */
.hk-calculate-section {
  .hk-calculate-container {
    background-color: $white-color;
    padding-top: 40px;
    padding-bottom: 40px;

    .hk-section-title {
      margin-bottom: 65px;
    }
  }
}

/* PRODUCT COMPARISON SECTION */
.hk-teilverkauf-page.hk-product-comparison-section {
  background-color: $neutral-lightest-color;
}
