.hk-faq-page {
  background-color: $neutral-light-color;
  padding-bottom: 37px;

  .section-title {
    @include typography-heading-h3;
    color: $primary-color;
    text-align: center;
    margin-bottom: 20px;
  }

  .hk-faq-card {
    background-color: $white-color;
    padding: 15px 25px;
    border-radius: 2px;

    .hk-faq-item {
      padding: 15px 0;
      &:not(:last-child) {
        border-bottom: 1px solid $neutral-light-color;
      }

      &.collapsed {
        .toggler {
          background-color: $neutral-light-color;
          color: $primary-color;

          span {
            color: $neutral-darkest-color;
            font-size: 23px;
            line-height: 35px;
            transform: rotate(0deg);
            transition: all 0.2s ease-in-out;
          }
        }
      }
    }

    .title,
    .hk-faq-content {
      @include typography-body-regular;
      color: $primary-color;
      margin-bottom: 0;
    }

    .hk-faq-header {
      display: flex;
      align-items: center;
      gap: 12px;

      .toggler {
        background-color: $primary-color;
        border-radius: 1px;
        color: $white-color;
        cursor: pointer;
        width: 30px;
        height: 30px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        span {
          color: $neutral-light-color;
          font-size: 23px;
          line-height: 35px;
          transform: rotate(45deg);
          transition: all 0.2s ease-in-out;
        }
      }
    }

    .hk-faq-content {
      padding-top: 15px;
      padding-left: 42px;
    }
  }

  @media screen and (min-width: 992px) {
    padding-bottom: 80px;

    .section-title {
      @include typography-heading-h2;
      margin-bottom: 40px;
    }

    .hk-faq-item {
      &.collapsed .toggler span {
        color: $primary-color !important;
      }

      .toggler span {
        color: $neutral-light-color;
      }
    }

    .hk-faq-header {
      gap: 20px;
      padding-right: 80px;
    }

    .hk-faq-content {
      padding-left: 50px;
      padding-right: 80px;
    }
  }
}
