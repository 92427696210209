.kontakt-page {
  .hk-kontakt-hero {
    .hk-section-title {
      @include typography-heading-h3;
      color: $primary-color;
      margin-bottom: 20px;
    }

    .hk-section-subtitle {
      @include typography-heading-h6;
      margin-bottom: 20px;
    }

    @media screen and (min-width: 768px) {
      .hk-section-title {
        @include typography-heading-h2;
      }
      .hk-section-subtitle {
        margin-bottom: 32px;
      }
    }
  }
}
