.bg-primary-color {
  background-color: $primary-color;
}
.bg-secondary-color {
  background-color: $secondary-color;
}
.bg-light-gray-color {
  background-color: $light-gray-color;
}
.bg-neutral-light-color {
  background-color: $neutral-light-color;
}
.bg-neutral-lightest-color {
  background-color: $neutral-lightest-color;
}
.bg-white-color {
  background-color: $white-color;
}

/* Text color */
.text-primary-color {
  color: $primary-color;
}
.text-light-gray-color {
  color: $light-gray-color;
}
.text-neutral-light-color {
  color: $neutral-light-color;
}
.text-neutral-lightest-color {
  color: $neutral-lightest-color;
}
.text-neutral-darkest-color {
  color: $neutral-darkest-color;
}
