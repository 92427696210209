.uber-uns-page {
  .hk-uber-uns-hero {
    background: url('/assets/images/ueber-uns-hero-bg-vertical.jpg') no-repeat
      center top/cover $primary-color;

    padding-top: calc($main-nav-height-sm + 40px);
    padding-bottom: 90px;

    color: $white-color;

    header.title-description {
      padding-left: 14px;
      padding-right: 14px;
    }

    .hero-title {
      font-size: 39px;
      font-weight: 400;
      line-height: 47px;
      margin-bottom: 12px;
    }

    .hero-description {
      @include typography-body-regular;
      margin-bottom: 40px;
    }
  }

  @media screen and (min-width: 992px) {
    .hk-uber-uns-hero {
      background: url('/assets/images/building-dark.png') no-repeat center
        top/cover $primary-color;
      text-align: center;
      padding-top: calc($main-nav-height-lg + 76px);
      padding-bottom: 180px;

      header.title-description {
        padding-left: 0px;
        padding-right: 0px;
      }

      .hero-title {
        margin-bottom: 20px;
      }

      .hero-description {
        @include typography-heading-h6;
        margin-bottom: 57px;
      }
    }
  }
}

/* SERVICE SECTION */
.hk-customer-service-section {
  .hk-section-title {
    color: $primary-color;
    margin-bottom: 20px;
  }

  .hk-section-subtitle {
    margin-bottom: 24px;
  }

  .hk-service-item {
    .image {
      margin-bottom: 24px;
      img {
        width: 100%;
      }
    }

    .quote {
      color: $light-black-color;
      font-family: Lora, system-ui, serif;
      font-weight: 500;
      font-style: italic;
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 12px;
    }

    .name {
      @include typography-heading-h4;
      @include typography-semibold;
      color: $primary-color;
    }

    .designation {
      @include typography-heading-h6;
      margin-bottom: 24px;
    }
  }

  @media screen and (min-width: 992px) {
    .hk-section-subtitle {
      margin-bottom: 40px;
    }
    .designation {
      margin-bottom: 0;
    }
  }
}

/* PARTNER SECTION */
.hk-our-partners-section {
  .partner-location-map {
    margin-bottom: 20px;
  }

  .partner-maxx-capital {
    margin-bottom: 40px;

    img {
      width: min(285px, 80%);
    }
  }
  .partner-compexx-finanz {
    margin-bottom: 30px;

    img {
      width: min(206px, 80%);
    }
  }
  .partner-bocker {
    margin-bottom: 30px;

    img {
      max-width: min(240px, 80%);
    }
  }
  .partner-isz-immobilien {
    margin-bottom: 30px;

    img {
      max-width: min(173px, 80%);
    }
  }
  .partner-grossmann-berger {
    margin-bottom: 30px;

    img {
      max-width: min(170px, 80%);
    }
  }

  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }
}

/* BOARD SECTION */
.hk-board-section {
  background-color: $primary-color;
  color: $white-color;

  .hk-section-title {
    @include typography-heading-h3;
    color: $white-color;
    margin-bottom: 20px;
  }

  .hk-section-subheading {
    @include typography-heading-h5;
    @include typography-semibold;
    color: $white-color;
    margin-bottom: 10px;
  }

  .hk-section-subtitle {
    @include typography-body-regular;
    color: $white-color;
    margin-bottom: 24px;
  }

  .hk-board-item {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
    text-align: center; /* Ensure text is centered */
    .image {
      margin-bottom: 24px;
      img {
        max-width: 30%;
      }
    }
    .name {
      @include typography-heading-h4;
      @include typography-semibold;
    }
    .designation {
      @include typography-heading-h6;
      margin-bottom: 12px;
      font-family: Lora, serif;
    }
    .description {
      @include typography-body-regular;
      margin-bottom: 24px;
    }
  }

  @media screen and (min-width: 992px) {
    .hk-board-item {
      .description {
        margin-bottom: 0px;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .hk-section-title {
      @include typography-heading-h2;
      margin-bottom: 30px;
    }

    .hk-section-subtitle {
      margin-bottom: 40px;
    }
  }
}

/* ADVISORY SECTION */
.hk-advisory-board-section {
  .hk-section-title {
    @include typography-heading-h3;
    margin-bottom: 20px;
  }

  .hk-section-subtitle {
    @include typography-heading-h6;
    margin-bottom: 24px;
  }

  .advisory-board-item {
    padding-bottom: 24px;

    .title {
      @include typography-heading-h4;
      @include typography-semibold;
      color: $primary-color;
      margin-bottom: 10px;
    }
    .address {
      @include typography-body-regular;
    }
  }

  @media screen and (min-width: 768px) {
    .hk-section-title {
      @include typography-heading-h2;
      margin-bottom: 0;
    }
    .hk-section-subtitle {
      margin-bottom: 40px;
    }

    .advisory-board-item {
      padding-bottom: 0;
      .title {
        white-space: nowrap;
      }
    }
  }
}
