body.form-screen {
  background-color: $neutral-light-color;
}

.form-screen-hero-section {
  padding-top: calc(10px + $main-nav-height-sm);
  padding-bottom: calc(10px + $main-nav-height-sm);

  @media screen and (min-width: 992px) {
    padding-top: calc(60px + $main-nav-height-lg);
    padding-bottom: calc(60px + $main-nav-height-lg);
  }
}
