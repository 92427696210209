.affiliate {
  .affiliate-hero {
    .hk-section-title {
      @include typography-heading-h3;
      color: $primary-color;
    }

    .hk-section-subtitle {
      @include typography-body-regular;

      a {
        color: $secondary-color;
        text-decoration: none;
      }
    }

    @media screen and (min-width: 768px) {
      .hk-section-title {
        @include typography-heading-h2;
      }

      .hk-section-subtitle {
        @include typography-heading-h6;
      }
    }
  }

  .hk-page-section.form-section {
    padding-top: 46px;
  }

  .hk-form-step-row {
    --bs-gutter-y: 40px;
  }

  .hk-step-counter {
    @include typography-heading-h1;
    @include typography-medium;
    color: $primary-color;
  }

  .hk-form-header {
    margin-bottom: 28px;

    &.payout-form-header {
      margin-bottom: 16px;
    }

    @media screen and (min-width: 768px) {
      margin-top: 10px;
    }
  }

  .form-title {
    @include typography-heading-h3;
    color: $primary-color;
  }

  .form-subtitle {
    @include typography-body-regular;
  }

  .living-with-partner {
    margin-top: 28px;
    margin-bottom: 24px;
  }

  .radio-box-container {
    display: flex;
    align-items: center;
    gap: 32px;

    @media screen and (min-width: 768px) {
      gap: 28px;
    }
  }

  .objektdetails-form-rows {
    --bs-gutter-y: 28px;

    .objektart-radio-row {
      --bs-gutter-y: 24px;

      .form-further {
        margin-top: 20px;
      }
    }
  }

  #submitBtnRow {
    margin-top: 45px;
  }

  .hk-money-input::placeholder {
    @include typography-body-regular;
    color: $neutral-darkest-color;
  }
}
