/* OUR PRODUCTS SECTION */
.hk-our-products-section {
  background-color: $primary-color;

  .hk-section-title {
    color: $white-color;
  }

  .hk-card {
    .title {
      margin-bottom: 20px;
    }
    .content {
      margin-bottom: 24px;
    }
    .hk-checklist-container {
      margin-bottom: 20px;
      display: grid;
      gap: 4px;
    }

    .hk-checklist-item {
      @include typography-body-regular;
      display: flex;
      gap: 12px;
    }

    .hk-action-btns {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  .hk-product-image {
    max-width: min(75%, 251px);
    margin-bottom: 20px;

    @media screen and (min-width: 992px) {
      max-width: min(75%, 313px);
    }
  }

  @media screen and (min-width: 992px) {
    .hk-card {
      .title {
        @include typography-heading-h2;
      }
      .content {
        margin-bottom: 20px;
      }

      .hk-checklist-container {
        margin-bottom: 40px;
      }
    }
    .columns-single .hk-card {
      padding: 52px 82px;
    }
    .columns-double .hk-card {
      padding: 40px;
    }
  }

  @media screen and (min-width: 768px) {
    .hk-card {
      .hk-action-btns {
        flex-direction: row;
        gap: 24px;
      }
    }
  }
}
