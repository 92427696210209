.partner-page {
  .hk-uber-uns-hero {
    background: url('/assets/images/ueber-uns-hero-bg-vertical.jpg') no-repeat
      center top/cover $primary-color;

    padding-top: calc($main-nav-height-sm + 40px);
    padding-bottom: 90px;

    color: $white-color;

    .hero-title {
      font-size: 39px;
      font-weight: 400;
      line-height: 47px;
      margin-bottom: 12px;
    }

    .hero-description {
      @include typography-heading-h6;
      margin-bottom: 40px;
    }
  }

  .partner-advantages {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 24px;

    li {
      @include typography-heading-h6;
      display: flex;
      align-items: flex-start;
      gap: 8px;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      i {
        transform: rotate(-5deg);
        font-size: 18px;
        font-weight: bold;
        line-height: 1.5;
      }
    }
  }

  @media screen and (min-width: 992px) {
    .hk-uber-uns-hero {
      background: url('/assets/images/building-dark.png') no-repeat center
        top/cover $primary-color;
      text-align: center;
      padding-top: calc($main-nav-height-lg + 76px);
      padding-bottom: 180px;

      .hero-title {
        margin-bottom: 20px;
      }

      .hero-description {
        @include typography-heading-h6;
        margin-bottom: 57px;
      }
    }

    .partner-advantages {
      margin-bottom: 24px;
    }

    .hk-payout-step-section {
      .hk-payout-step {
        padding-top: 20px;
        padding-bottom: 20px;
        &:last-of-type {
          border-bottom: 1px solid rgba($secondary-color-light, 0.5);
        }
      }

      .hk-payout-step-cta {
        margin-top: 60px;
      }
    }
  }

  #contact-form-section {
    .section-header {
      margin-bottom: 20px;

      .section-title {
        @include typography-heading-h3;
        margin-bottom: 20px;
      }

      .section-subtitle {
        @include typography-body-regular;
        margin-bottom: 0;
      }

      @media screen and (min-width: 768px) {
        margin-bottom: 40px;

        .section-title {
          @include typography-heading-h2;
        }
      }
    }
  }
}
