/* MAKE USE HEIMKAPITAL SECTION */
.hk-make-use-heimkapital-section {
  .title {
    @include typography-heading-h4;
    color: $primary-color;
    margin-bottom: 0;
  }

  .description {
    @include typography-body-regular;
  }

  .truspilot-container {
    border-radius: 2px;
    margin-bottom: 24px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .trustpilot-widget {
      background-color: $white-color;
      border-radius: 4px;
      display: inline-block;
      padding: 10px 12px;
      position: absolute !important;
      top: 10px;
      left: 10px;
    }
  }

  @media screen and (min-width: 992px) {
    background: url('/assets/images/couple-large.png') no-repeat 40% top/cover;
    padding: 120px 0;
    position: relative;
    margin-top: -1px;

    .title {
      @include typography-heading-h2;
    }
    .description {
      margin-top: 12px;
      margin-bottom: 20px;
    }

    .truspilot-container {
      position: absolute;
      margin: 0;
      right: 30px;
      bottom: 20px;
      height: 105px;
      width: 150px;
      padding: 0;

      .trustpilot-widget {
        top: 0;
        left: 0;
      }
    }
  }
}
