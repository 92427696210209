.press-list-container {
  .hk-press-download-kontakt-section {
    .row {
      margin-bottom: 24px;

      &:last-of-type {
        margin-bottom: 40px;
      }

      @media screen and (min-width: 768px) {
        margin-bottom: 40px;

        &:last-of-type {
          margin-bottom: 60px;
        }
      }
    }
  }

  .page-title {
    margin-bottom: 20px;

    @media screen and (min-width: 768px) {
      margin-bottom: 40px;
    }
  }
  .box-title {
    @include typography-heading-h5;
    color: $primary-color;
    margin-bottom: 8px;

    @media screen and (min-width: 768px) {
      @include typography-heading-h4;
      margin-bottom: 20px;
    }
  }

  .hk-press-card {
    margin-bottom: 24px;

    .date {
      @include typography-caption-regular;
      @include typography-semibold;
      margin-bottom: 16px;
      color: $secondary-color;
    }

    .title {
      @include typography-body-regular;
      color: $primary-color;
      margin-bottom: 24px;
    }

    .hk-post-card__read-btn {
      margin-bottom: 0;
    }

    &:hover {
      .arrow {
        transform: translateX(5px);
      }
    }

    @media screen and (min-width: 768px) {
      .title {
        @include typography-heading-h5;
      }
    }
  }
}

.press-note-details-page {
  .blockquote {
    display: grid;
    gap: 0;

    .quote-image {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .content {
      background-color: $neutral-light-color;
      display: grid;
      gap: 20px;
      padding: 28px 20px;

      p {
        margin: 0;
      }
      .quote-text,
      cite {
        @include typography-body-regular;
        line-height: 22.4px;
      }
    }

    @media screen and (min-width: 768px) {
      display: flex;

      .quote-image {
        width: 50%;
        flex-shrink: 0;
      }
      .content {
        padding: 28px 40px;
      }
    }
  }
}
