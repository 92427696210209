.custom-select-wrapper {
  position: relative;

  .hk-custom-select {
    cursor: pointer;
  }

  .down-arrow {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }

  .options-container {
    display: none;
    background: $neutral-lightest-color;
    border: 1px solid $neutral-light-color;
    border-radius: 0 0 2px 2px;
    box-shadow: 0 5px 10px rgba($dark-black-color, 0.1);
    list-style: none;
    width: 100%;
    padding: 10px 0;
    position: absolute;
    top: calc(100% + 4px);
    z-index: 10;

    .option {
      @include typography-body-regular;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 12px;

      &:hover {
        background-color: $neutral-light-color;
      }

      &.selected {
        background-color: $neutral-light-color;
      }

      &:not(.selected) .tick {
        display: none;
      }
      .lni {
        font-weight: bold;
        font-size: 14px;
      }
    }
  }

  &.open {
    .options-container {
      display: block;
    }
    .down-arrow {
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }
}
