.hk-btn {
  @include typography-body-regular;
  outline: none;
  border: none;
  color: $primary-color;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px;
  border-radius: 2px;
  text-align: center;
  text-decoration: none;

  a {
    text-decoration: none;
  }

  @media screen and (min-width: 768px) {
    width: 240px;
    padding: 14px 22px;
  }
}

.hk-btn-primary {
  background-color: $primary-color;
  color: $white-color;

  a {
    color: $white-color;
  }

  &:hover {
    background-color: $nav-link-hover-bg-color;
  }
}

.hk-btn-secondary {
  background-color: $neutral-light-color;

  &:hover {
    background-color: #d1d6dc;
  }
}
