.hk-calculator {
  .hk-calculator-input-row {
    display: grid;
    gap: 20px;
    margin-bottom: 36px;

    .hk-calculator-range {
      label {
        @include typography-body-regular;
        display: flex;
        align-items: center;
        gap: 14px;

        & > * {
          flex-shrink: 0;
        }
      }

      input {
        width: 100%;
      }

      .hk-value-label {
        font-size: 28px;
        font-weight: 700;
        line-height: 42px;
        color: $primary-color;
      }

      .hk-range-label {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .hk-range-stop {
          @include typography-caption-regular;
        }
      }
    }
  }

  .logo {
    margin-bottom: 16px;
    img {
      width: min(200px, 65%);
    }
  }

  .home-artwork {
    display: flex;
    justify-content: center;
  }

  .hk-calculator-info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 35px;

    .hk-calculator-info {
      width: calc(50% - 7px);
      text-align: center;
      display: grid;
      justify-items: center;

      .percent {
        @include typography-heading-h4;
        @include typography-semibold;
        background: $primary-color;
        color: $white-color;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 74px;
        height: 36px;
        margin-bottom: 7px;
      }

      .label {
        @include typography-body-regular;
        @include typography-semibold;
        color: $primary-color;
      }

      .description {
        @include typography-body-regular;
        color: $primary-color;
      }

      &#yourShare {
        .percent {
          background-color: $neutral-darkest-color;
        }
        .label,
        .description {
          color: $neutral-darkest-color;
        }
      }
    }
  }

  .custom-range {
    -webkit-appearance: none;
    appearance: none;
    background-color: $neutral-light-color;
    cursor: pointer;
    height: 4px;
    width: 100%;
  }

  .custom-range:focus {
    outline: none;
  }

  /******** Chrome, Safari, Opera and Edge Chromium styles ********/
  /* slider track */
  .custom-range::-webkit-slider-runnable-track,
  .custom-range::-moz-range-track {
    border-radius: 0;
    height: 4px;
  }

  /* slider thumb */
  .custom-range::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: 0px; /* Centers thumb on the track */
    background-color: $primary-color;
    border-radius: 50%;
    height: 16px;
    width: 16px;
  }

  .custom-range:focus::-webkit-slider-thumb,
  .custom-range:focus::-moz-range-thumb {
    outline: none;
  }

  /*********** Firefox styles ***********/
  /* slider track */

  /* slider thumb */
  .custom-range::-moz-range-thumb {
    background-color: $primary-color;
    border: none; /*Removes extra border that FF applies*/
    border-radius: 50%;
    height: 16px;
    width: 16px;
  }
}
