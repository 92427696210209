.hk-hero-section {
  padding-top: calc(36px + $main-nav-height-sm);
  padding-bottom: 450px;

  @media screen and (min-width: 768px) {
    padding-top: calc(76px + $main-nav-height-lg);
    padding-bottom: calc(2px + $main-nav-height-lg);
  }
}

.hk-page-section {
  padding: 40px 0;

  .hk-section-title {
    @include typography-heading-h3;
    color: $primary-color;
    margin-bottom: 24px;
  }

  .hk-section-subtitle {
    @include typography-body-regular;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 768px) {
    padding: 80px 0;

    .hk-section-title {
      @include typography-heading-h2;
      margin-bottom: 40px;
    }
  }
}

.hero-counters {
  display: grid;
  gap: 40px;
  text-align: center;

  .counter {
    font-size: 62px;
    font-weight: 700;
    line-height: 93px;
  }
  .label {
    @include typography-heading-h6;
  }

  @media screen and (min-width: 992px) {
    display: flex;
    justify-content: center;
    gap: 40px;
  }
}
