/* HERO AREA */
.hk-grundstuecksverkauf-hero {
  background: url('/assets/images/man-sitting-on-sofa-with-eyeglasses-vertical.png')
    50% 70% / cover no-repeat;
  padding-bottom: 350px;

  .hk-grundstuecksverkauf-hero-content {
    background-color: transparent;
    padding: 0px;

    .title {
      font-weight: 400;
      font-size: 34px;
      line-height: 41px;
      margin-bottom: 12px;
      color: $primary-color;

      .bold {
        font-weight: 700;
        &::after,
        &::before {
          content: ' ';
        }
      }
      .secon-line {
        letter-spacing: -1.2px;
      }
    }

    .content {
      @include typography-heading-h6;
      margin-bottom: 28px;
    }

    .cta {
      @include typography-body-regular;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 52px;
      width: 100%;
      padding: 16px;
      color: $white-color;
      text-decoration: none;
      background-color: $primary-color;
      margin-bottom: 20px;
      cursor: pointer;

      & > div {
        gap: 16px;
      }

      &:hover {
        background-color: $nav-link-hover-bg-color;
        .arrow {
          transform: translateX(10px);
        }
      }

      .arrow {
        transition: all 0.2s ease-in-out;
      }
    }
  }

  @media screen and (min-width: 480px) {
    padding-bottom: 450px;
  }

  @media screen and (min-width: 768px) {
    background: url('/assets/images/man-sitting-on-sofa-with-eyeglasses.png')
      center 30% / cover no-repeat;
    padding-bottom: 80px;

    .hk-grundstuecksverkauf-hero-content {
      padding: 40px 0px;
      max-width: min(484px, 100%);
      .title {
        font-size: 39px;
        line-height: 46px;
      }
      .content {
        margin-bottom: 32px;
      }

      .cta {
        @include typography-heading-h6;
        height: 59px;
      }
    }
  }
}

/* WHY CHOOSE Grundkapital SECTION */
.product-grundkapital {
  .hk-why-choose-section {
    background-color: $primary-color;

    .hk-section-title .grundstuecksverkauf-logo {
      max-width: 214px;
    }

    @media screen and (min-width: 768px) {
      .hk-section-title .grundstuecksverkauf-logo {
        max-width: 284px;
      }
      .hk-card {
        padding-bottom: 32px;
      }
    }
  }
}

/* CALCULATOR SECTION */
.hk-calculate-section {
  .hk-calculate-container {
    background-color: $white-color;
    padding-top: 40px;
    padding-bottom: 40px;

    .hk-section-title {
      margin-bottom: 65px;
    }
  }
}

/* PRODUCT COMPARISON SECTION */
.hk-grundstuecksverkauf-page.hk-product-comparison-section {
  background-color: $neutral-lightest-color;
}
