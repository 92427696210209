.hk-form {
  .hk-form-row {
    --bs-gutter-y: 20px;
    --bs-gutter-x: 20px;
  }

  #submit-btn-row {
    margin-top: 24px;

    @media screen and (min-width: 768px) {
      margin-top: 28px;
    }
  }

  .hk-form-control {
    @include typography-body-regular;
    background-color: $neutral-lightest-color;
    border: 1px solid $neutral-light-color;
    border-radius: 2px;
    padding: 14px 16px;

    &:focus {
      box-shadow: none;
      border-color: $secondary-color;
      outline: none;
    }
  }

  .form-floating {
    label {
      @include typography-body-regular;
      color: $neutral-darkest-color;
      line-height: 21px;
      padding: 16px;
    }

    .hk-form-control {
      min-height: unset;
      &:not(textarea) {
        height: 52px;
      }

      & ~ label {
        &:after {
          background: transparent;
        }
      }
    }

    @media screen and (min-width: 768px) {
      label {
        line-height: 1;
      }
      .hk-form-control:not(textarea) {
        height: 48px;
      }
    }
  }

  .hk-form-textarea {
    height: 200px;
    resize: none;
  }

  .is-invalid {
    border-color: $danger-color;
    background-image: unset;
  }

  .form-field-label-top {
    @include typography-caption-regular;
    margin-bottom: 4px;
  }
}

.hk-form-checkbox {
  display: flex;
  gap: 8px;
  align-items: center;

  label {
    @include typography-body-regular;
    order: 1;

    a {
      text-decoration: none;
      color: $secondary-color;
    }
  }

  input {
    display: none;
    &:checked ~ .checkbox-image-container {
      .emptybox {
        display: none;
      }
      .checked {
        display: block;
      }
    }

    &.is-invalid ~ .checkbox-image-container {
      border-color: $danger-color;
    }
  }

  .checkbox-image-container {
    cursor: pointer;
    background-color: $neutral-lightest-color;
    border: 1px solid $neutral-light-color;
    border-radius: 1px;

    .emptybox {
      display: block;
      width: 28px;
      height: 28px;
    }

    .checked {
      display: none;
      width: 28px;
      height: 28px;
    }
  }
}

.hk-custom-radio {
  position: relative;
  height: 28px;
  display: flex;
  align-items: center;

  input[type='radio'] {
    position: absolute;
    left: -100000px;

    &:checked ~ label {
      &::before {
        background-color: $primary-color;
      }
      &::after {
        background-color: $neutral-light-color;
      }
    }
  }

  label {
    @include typography-body-regular;
    cursor: pointer;
    padding-left: 35px;

    &::before {
      content: '';
      background-color: $neutral-lightest-color;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    &::after {
      content: '';
      background-color: transparent;
      border-radius: 50%;
      width: 14px;
      height: 14px;
      position: absolute;
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
    }
  }

  @media screen and (min-width: 768px) {
    height: 24px;

    input[type='radio']:checked ~ label {
      &::before {
        background-color: $neutral-lightest-color;
      }
      &::after {
        background-color: $primary-color;
      }
    }

    label {
      &::before {
        background-color: $neutral-lightest-color;
        border: 1px solid $neutral-light-color;
        width: 28px;
        height: 28px;
      }

      &::after {
        width: 16px;
        height: 16px;
        left: 6px;
      }
    }
  }
}

.form-screen {
  background-color: $neutral-light-color;

  .form-card {
    background-color: $white-color;
    // border: 1px solid $light-gray-color;
    border-radius: 2px;
    position: relative;
    padding: 46px 20px 32px;

    .form-title {
      @include typography-heading-h3;
      color: $primary-color;
      margin-bottom: 20px;
    }
    .form-subtitle {
      @include typography-body-regular;
      margin-bottom: 20px;
    }
  }

  .form-screen__dots {
    display: flex;
    gap: 12px;
    position: absolute;
    top: 24px;
    right: 32px;

    .form-screen__dot {
      background-color: $neutral-dark-color;
      border-radius: 50%;
      width: 8px;
      height: 8px;

      &.form-screen__dot--active {
        background-color: $primary-color;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .form-card {
      padding-top: 40px;
      padding-top: 40px;

      .form-title {
        margin-bottom: 16px;
      }

      .form-subtitle {
        margin-bottom: 28px;
      }
    }
  }
}

/* REMOVING ARROWS FROM NUMBER FIELD */
/* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* IE 10+ */
input[type='number'] {
  -ms-appearance: textfield;
}
