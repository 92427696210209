/* HERO AREA */
.hk-home-hero {
  background: url('/assets/images/home-hero-vertical.jpg') 50% bottom / cover
    no-repeat;

  .hk-home-hero-content {
    background-color: $white-color;
    padding: 24px 20px;

    .title {
      font-weight: 400;
      font-size: 34px;
      line-height: 41px;
      margin-bottom: 12px;
      color: $primary-color;

      span {
        font-weight: 700;
        &::before {
          content: ' ';
        }
      }
    }

    .content {
      @include typography-heading-h6;
      margin-bottom: 28px;
    }

    .cta {
      @include typography-body-regular;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 52px;
      width: 100%;
      padding: 16px;
      color: $white-color;
      text-decoration: none;
      background-color: $primary-color;
      margin-bottom: 20px;
      cursor: pointer;

      & > div {
        gap: 16px;
      }

      &:hover {
        background-color: $nav-link-hover-bg-color;
        .arrow {
          transform: translateX(10px);
        }
      }

      .arrow {
        transition: all 0.2s ease-in-out;
      }
    }
  }

  @media screen and (min-width: 768px) {
    background: url('/assets/images/home-hero.jpg') right 30% / cover no-repeat;

    .hk-home-hero-content {
      padding: 40px 60px;
      min-width: 461px;

      .title {
        font-size: 50px;
        line-height: 60px;
      }
      .content {
        margin-bottom: 32px;
      }
      .cta {
        @include typography-heading-h6;
        height: 59px;
      }
    }
  }
}

/* WHY CHOOSE HEIMKAPITAL SECTION */
.hk-choose-why-section {
  background-color: $neutral-light-color;
}

/* CUSTOMER EXPERIENCE SECTION */
.hk-customer-experience-section {
  .hr-divider {
    margin-top: 37px;
    margin-bottom: 58px;
  }

  .hk-testimonial-carousel {
    margin-bottom: 24px;
  }

  @media screen and (min-width: 992px) {
    .hk-testimonial-carousel {
      margin-bottom: 0px;
    }
  }
}

/* HOW TO USE SECTION */
.hk-how-to-use-section {
  background: url('/assets/images/home-how-to-use-bg-vertical.png') no-repeat
    50% bottom / cover;
  padding-bottom: 300px;

  @media screen and (min-width: 768px) {
    background: url('/assets/images/home-how-to-use-bg.jpg') no-repeat top right /
      cover;
    padding-bottom: 80px;
  }
}

/* FIND OUT MORE SECTION */
.hk-find-more-section {
  background-color: $primary-color;
  padding: 40px 20px;

  .image {
    height: 100%;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .hk-info-card {
    background-color: $white-color;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 20px;
    height: 100%;
    text-align: center;

    .title,
    .info-text {
      margin: 0;
    }

    .title {
      color: $primary-color;
    }

    .contact {
      display: grid;
      gap: 12px;
    }

    .phone,
    .email {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      color: $primary-color;

      .icon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;

        img {
          width: 100%;
        }
      }

      a {
        color: $primary-color;
        text-decoration: none;

        &:hover {
          color: $neutral-dark-color;
        }
      }
    }

    @media screen and (min-width: 768px) {
      text-align: left;
    }

    @media screen and (min-width: 992px) {
      padding: 40px 60px;
      .title {
        @include typography-heading-h2;
      }

      .phone,
      .email {
        justify-content: start;

        .icon {
          width: 17px;
          height: 17px;
        }
      }
    }

    @media screen and (max-width: 991px) {
      .hk-btn {
        width: 100%;
      }
    }
  }

  @media screen and (min-width: 768px) {
    padding: 80px 0;
  }
}

/* PRODUCT COMPARISION SECTION */
.hk-product-comparison-section {
  .hk-product-compare-table-wrapper {
    width: 100%;
    overflow-x: auto;

    table {
      width: calc(100vw - 30px);
    }

    tr {
      border-bottom: 1px solid $light-gray-color;
    }

    th,
    td {
      width: 20%;
      padding: 0;

      &:nth-child(2),
      &:nth-child(3) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    td {
      @include typography-body-regular;
      height: 100px;
    }

    .th,
    .td {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .th {
      @include typography-body-regular;
      align-items: flex-end;
      height: 72px;
      padding: 16px 25px;
    }

    .empty-check {
      width: 23px;
      height: 23px;
      background-color: $neutral-light-color;
      border-radius: 50%;
    }

    .bg-light-gray {
      background-color: $neutral-light-color;
    }
  }

  @media screen and (min-width: 992px) {
    .hk-product-compare-table-wrapper {
      table {
        width: 100%;
      }
    }
  }
}

/* CLIENTS SECTION */
.hk-clients-section {
  background-color: $neutral-lightest-color;

  .client-logo-col {
    margin-bottom: 16px;
    &.order-4,
    &.order-5 {
      margin-bottom: 0;
    }
  }

  .client-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    img {
      display: block;
      max-width: min(115px, 90%);
      max-height: 30px;
    }
  }

  @media screen and (min-width: 768px) {
    .client-logo-col {
      margin-bottom: 0;
      &.order-md-0,
      &.order-md-1,
      &.order-md-2 {
        margin-bottom: 40px;
      }
    }
    .client-logo {
      img {
        max-width: min(275px, 90%);
        max-height: 60px;
      }
    }
  }
}

/* BENEFITS CTA SECTION */
.hk-benefit-cta-section {
  background-color: transparent;
  padding-top: 0;

  .hk-benefit-content-area {
    background-color: $white-color;
  }

  .hk-benefit-cta-img {
    height: 200px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center 25%;
    }
  }

  .hk-benefit-cta-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;

    .title {
      @include typography-heading-h5;
      color: $primary-color;
      margin-bottom: 0;
    }

    .info {
      @include typography-body-regular;
      margin: 0;
    }
    .action-btn .hk-btn {
      width: 100%;
    }
  }

  @media screen and (min-width: 576px) {
    .hk-benefit-cta-img {
      height: 300px;
    }
  }

  @media screen and (min-width: 992px) {
    background: url('/assets/images/home-hk-benefit-bg.jpg') center/cover
      no-repeat;
    padding-top: 80px;

    .hk-benefit-content-area {
      display: grid;
      grid-template-areas: 'cta-image cta-info';
      grid-template-columns: min(5fr, 414px) 7fr;
      align-items: center;

      .hk-benefit-cta-img {
        grid-area: cta-image;
        width: 414px;
        height: 100%;
      }

      .hk-benefit-cta-info {
        background: $white-color;
        grid-area: cta-info;
        padding: 64px 60px;
        white-space-collapse: break-spaces;

        .title {
          @include typography-heading-h2;
        }

        .action-btn .hk-btn {
          width: 240px;
        }
      }
    }
  }
}
