.info.info-icon {
  cursor: help;
}

.hk-popover {
  --bs-popover-bg: #20356f;
  --bs-popover-max-width: min(320px, 90vw);
  width: min(320px, 90vw);
  border-radius: 4px;
  font-family: Poppins, system-ui, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .popover-body {
    border-radius: 4px;
    color: $white-color;
    padding: 0;

    .hk-info-popup {
      padding: 22px 36px 22px 22px;
      position: relative;

      .close {
        background-color: $white-color;
        border-radius: 50%;
        color: #9da4b1;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 26px;
        height: 26px;
        position: absolute;
        right: 10px;
        top: 15px;

        &:hover {
          color: $dark-black-color;
        }
      }

      .title {
        @include typography-caption-regular;
        @include typography-medium;
      }

      .content {
        @include typography-caption-regular;
        margin-top: 10px;
      }
    }
  }
}
