.hk-infobox {
  background: $primary;
  padding: 0.5rem 1.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  * {
    color: $white-color !important;
  }

  img {
    margin-bottom: 1rem;
  }
  p {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .hk-btn-primary {
    background-color: $secondary-color;

    &:hover {
      background-color: $secondary-color-light;
    }
  }
}

.btn-calculator {
  color: $white-color !important;
  font-weight: bold;
  white-space: nowrap;
  vertical-align: center;
  // margin: 0 2rem;
  background-color: $secondary-color;

  &:hover {
    background-color: $secondary-color-light;
  }

  img {
    width: 2rem !important;
    margin: 0 0.5rem 0 0 !important;
    vertical-align: center;
  }
}
