.hk-payout-step {
  border-top: 1px solid rgba($secondary-color-light, 0.5);
  padding: 20px 0;

  .sequence {
    @include typography-heading-h1;
    @include typography-medium;
    color: $primary-color;
    width: 80px;
    height: 66px;
    flex-shrink: 0;
  }

  .content {
    display: grid;
    gap: 4px;
    align-content: space-evenly;

    .title {
      @include typography-heading-h5;
      color: $primary-color;
    }

    .description {
      @include typography-body-regular;

      a {
        text-decoration: none;
        color: $secondary-color;
      }
    }
  }

  @media screen and (min-width: 768px) {
    display: flex;
    gap: 40px;
    padding: 35px 0;

    .content {
      .title {
        @include typography-heading-h3;
      }
    }
  }
}
