/* PAYOUT STEPS SECTION */
.hk-payout-step-section {
  .hk-payout-step-cta {
    margin-top: 20px;
  }

  @media screen and (min-width: 768px) {
    .hk-payout-step-cta {
      margin-top: 25px;
    }
  }
}
