.hk-card {
  background-color: $white-color;
  padding: 28px 20px;
  margin-bottom: 24px;

  .icon {
    margin-bottom: 20px;
  }

  .title {
    @include typography-heading-h5;
    color: $primary-color;
    margin: 0;
    margin-bottom: 8px;
    hyphens: none;
  }

  .content {
    @include typography-body-regular;
    color: $dark-black-color;
    margin: 0;
  }

  @media screen and (min-width: 768px) {
    padding: 32px;
    padding-bottom: 40px;
    margin-bottom: 0;
    height: 100%;

    .title {
      @include typography-heading-h5;
      @include typography-medium;
      margin-bottom: 12px;
      letter-spacing: -0.01rem;
    }
  }
}
