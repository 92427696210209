@mixin typography-body-regular {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

@mixin typography-heading-h1 {
  font-weight: 400;
  font-size: 42px;
  line-height: 63px;
}

@mixin typography-heading-h2 {
  font-weight: 400;
  font-size: 35px;
  line-height: 53px;
}

@mixin typography-heading-h3 {
  font-weight: 400;
  font-size: 29px;
  line-height: 44px;
}

@mixin typography-heading-h4 {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
}

@mixin typography-heading-h5 {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}

@mixin typography-heading-h6 {
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
}

@mixin typography-title-xs {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

@mixin typography-medium {
  font-weight: 500;
}
@mixin typography-semibold {
  font-weight: 600;
}
@mixin typography-bold {
  font-weight: 700;
}

@mixin typography-caption-regular {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

@mixin typography-caption-xs {
  font-size: 8px;
  font-weight: 400;
  line-height: 10px;
}

.hk-text-body-regular {
  @include typography-body-regular;
}

.hk-text-body-semibold {
  @include typography-body-regular;
  @include typography-semibold;
}

.hk-text-heading-h1 {
  @include typography-heading-h1;
}
.hk-text-heading-h2 {
  @include typography-heading-h2;
}
.hk-text-heading-h3 {
  @include typography-heading-h3;
}
.hk-text-heading-h4 {
  @include typography-heading-h4;
}
.hk-text-heading-h5 {
  @include typography-heading-h5;
}
.hk-text-heading-h6 {
  @include typography-heading-h6;
}
.hk-text-title-xs {
  @include typography-title-xs;
}
.hk-text-caption-regular {
  @include typography-caption-regular;
}
.hk-text-caption-xs {
  @include typography-caption-xs;
}

.hk-text-medium {
  @include typography-medium;
}

.hk-text-semibold {
  @include typography-semibold;
}

.hk-text-bold {
  @include typography-bold;
}
