/* COLORS */
$primary-color: #20356f;
$primary: $primary-color;
$secondary-color: #2573c0;
$secondary-color-light: #6f9dcb;
$danger-color: #b51616;

$dark-black-color: #1f292e;
$light-black-color: #263757;
$dark-gray-color: #777c84;
$light-gray-color: #d8dadd;

$neutral-darkest-color: #718094;
$neutral-dark-color: #8696af;
$neutral-light-color: #e7ecf3;
$neutral-lightest-color: #f4f6f9;
$white-color: #ffffff;

$nav-link-hover-text-color: $neutral-dark-color;
$nav-link-hover-bg-color: #17295a;

/* SIZE & SPACING */
$main-nav-height-lg: 84px;
$main-nav-height-sm: 60px;
