.club-page {
  .hk-page-section {
    padding: 40px 0;

    @media screen and (min-width: 992px) {
      padding: 80px 0;
    }
  }

  .hk-club-hero {
    background: url('/assets/images/couple-hero-image-vertical.png') no-repeat
      center 80% / cover $primary-color;

    padding-top: calc($main-nav-height-sm + 40px);
    padding-bottom: 450px;

    color: $white-color;

    .hero-title {
      font-size: 34px;
      font-weight: 400;
      line-height: 41px;
      margin-bottom: 12px;
    }

    .hero-description {
      @include typography-heading-h6;
      margin-bottom: 28px;
    }

    .cta {
      height: 52px;

      & > div {
        gap: 16px;
      }

      .arrow {
        transition: all 0.2s ease-in-out;
      }

      &:hover .arrow {
        transform: translateX(10px);
      }
    }

    @media screen and (min-width: 650px) {
      padding-bottom: 600px;
    }

    @media screen and (min-width: 992px) {
      background: url('/assets/images/couple-hero-image.png') no-repeat 80% 30% /
        cover $primary-color;
      padding-top: calc($main-nav-height-lg + 76px);
      padding-bottom: 200px;

      .hk-club-hero-content {
        max-width: 484px;
      }

      .hero-title {
        font-size: 39px;
        line-height: 47px;
      }

      .hero-description {
        @include typography-heading-h6;
        line-height: 29px;
        margin-bottom: 32px;
      }

      .cta {
        @include typography-heading-h6;
        max-width: 452px;
        height: 59px;
      }
    }
  }

  /* AT A GLANCE SECTION */
  .hk-club-at-glance-section {
    .card-row {
      --bs-gutter-y: 32px;
    }

    .hk-card {
      padding: 0;

      .icon {
        margin: 0 auto;
        margin-bottom: 24px;

        img:not(.care-icon) {
          width: 60px;
          max-height: 60px;
          object-fit: cover;
        }
      }

      .title {
        @include typography-heading-h5;
        @include typography-medium;
        margin-bottom: 12px;
      }
    }

    .cta {
      margin-top: 40px;
    }

    @media screen and (min-width: 992px) {
      .card-row {
        .col-lg-4 {
          max-width: 318px;
          // flex-grow: 0;
        }
      }
      .hk-card {
        .icon {
          margin-bottom: 20px;
        }
        .title {
          @include typography-heading-h6;
          @include typography-medium;
          margin-bottom: 8px;
        }
      }
    }
  }

  /* OFFERS SECTION */
  .hk-club-offer-section {
    background-color: $neutral-light-color;
    .offer-section-rows {
      gap: 20px;
    }

    .section-title {
      @include typography-heading-h3;
      color: $primary-color;
      margin-bottom: 20px;
      text-align: center;
    }

    .section-subtitle {
      @include typography-body-regular;
      margin: 0;
    }

    .content-card {
      background-color: $white-color;
      padding: 20px;

      .icon {
        margin-bottom: 30px;
      }
      .title {
        @include typography-heading-h5;
        @include typography-semibold;

        color: $primary-color;
        margin-bottom: 12px;
      }
      .description {
        @include typography-body-regular;
        margin-bottom: 30px;
      }
    }
    @media screen and (min-width: 992px) {
      background-color: $neutral-lightest-color;
      .offer-section-rows {
        gap: 83px;
      }

      .section-title {
        @include typography-heading-h2;
      }

      .section-subtitle {
        text-align: center;
      }

      .content-card {
        background-color: transparent;
        padding: 0;
        padding-right: 52px;

        .title {
          @include typography-heading-h4;
          @include typography-semibold;
        }
      }
    }
  }

  /* MORE OFFERS SECTION */
  .hk-more-offer-section {
    .more-offers {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 23px;
      max-width: 100%;
      margin-bottom: 24px;

      .offer-card {
        .image {
          margin-bottom: 12px;

          img {
            width: 100%;
          }
        }

        .label {
          @include typography-body-regular;
          color: #243137;
          text-align: center;
        }
      }

      @media screen and (min-width: 768px) {
        grid-template-columns: repeat(5, 1fr);
        gap: 36px;
        margin-bottom: 40px;
      }
    }
  }

  /* SERVICES SECTION */
  .hk-services-section {
    padding: 40px 0;
    background: url('/assets/images/club-services-vertical.png') center 30% /
      cover no-repeat;

    .logo {
      width: min(257px, 80%);
      margin: 0 auto 25px;
    }

    .services {
      list-style: none;
      padding: 0;

      .service {
        background-color: $primary-color;
        color: $white-color;
        padding: 5px 12px;
        margin: 0 auto 20px;
        width: max-content;
        max-width: 100%;
        text-align: center;
      }
    }

    @media screen and (min-width: 992px) {
      background: url('/assets/images/club-services.png') center center/cover
        no-repeat;

      .hk-section-subtitle {
        margin-bottom: 60px;
      }

      .logo {
        margin: 0;
        position: absolute;
        top: 60%;
        right: 10%;
        transform: translateY(-50%);
        width: min(429px, 35%);
      }

      .services {
        .service {
          @include typography-heading-h5;
          margin: 0;
          margin-bottom: 40px;
        }
        .service-1 {
          margin-left: calc(41% + var(--bs-gutter-x));
        }
        .service-2 {
          margin-left: calc(17% + var(--bs-gutter-x));
        }
        .service-3 {
          margin-left: 17%;
        }
        .service-4,
        .service-6 {
          margin-left: calc(8% + var(--bs-gutter-x));
        }
        .service-4 {
          margin-left: 8%;
        }
      }
    }
  }

  /* BENEFITS AND DISCOUNT SECTION */
  .hk-benefits-discounts-section {
    padding: 40px 0 !important;

    .hk-section-title,
    .hk-section-subtitle {
      margin-bottom: 20px;
      text-align: center;
    }

    .cta {
      background-color: $secondary-color;
      color: $white-color;

      &:hover {
        background-color: lighten($color: $secondary-color, $amount: 10);
      }
    }

    @media screen and (min-width: 768px) {
      .hk-section-subtitle {
        margin-bottom: 40px;
      }

      .cta {
        background-color: $white-color;
        color: $dark-black-color;

        &:hover {
          background-color: $nav-link-hover-bg-color;
          color: $white-color;
        }
      }
    }
  }
}
