.hk-post-container {
  $top-gap: calc($main-nav-height-sm + 20px);
  padding-top: $top-gap;
  padding-bottom: $top-gap;

  @media screen and (min-width: 768px) {
    $top-gap: calc($main-nav-height-lg + 76px);
    padding-top: $top-gap;
    padding-bottom: $top-gap;
  }

  h1,
  .page-title {
    @include typography-heading-h3;
    color: $primary-color;
    margin-bottom: 20px;

    @media screen and (min-width: 768px) {
      @include typography-heading-h2;
    }
  }

  .page-description {
    @include typography-heading-h6;
    margin-bottom: 20px;

    @media screen and (min-width: 768px) {
      margin-bottom: 40px;
    }
  }

  .toc-box {
    margin-bottom: 20px;

    #toc-title {
      @include typography-heading-h5;
      color: $primary-color;
      margin-bottom: 20px;
    }

    @media screen and (min-width: 768px) {
      margin-bottom: 32px;
      #toc-title {
        @include typography-heading-h4;
      }
    }
  }

  h2,
  h3,
  h4 {
    @include typography-heading-h4;
    color: $primary-color;
    padding-top: $top-gap;
    margin-top: calc($top-gap * -1);
    margin-bottom: 20px;

    @media screen and (min-width: 768px) {
      @include typography-heading-h3;
    }
  }

  h4 {
    @include typography-heading-h5;
    margin-bottom: 10px;

    @media screen and (min-width: 768px) {
      @include typography-heading-h4;
    }
  }

  p {
    @include typography-body-regular;
    margin-bottom: 20px;
    margin-top: 20px;

    strong {
      @include typography-semibold;
    }

    @media screen and (min-width: 768px) {
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }
  h4 + p {
    margin-top: 0;
  }

  .box {
    padding: 28px 20px;
    background-color: $neutral-light-color;

    @media screen and (min-width: 768px) {
      padding: 32px 40px;
    }
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      @include typography-body-regular;
      padding: 0;
      padding-left: 24px;
      margin-bottom: 4px;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: '';
        background: url('/assets/icons/chevron-right.svg') no-repeat center
          center/cover;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
      }
    }
  }

  ol {
    padding-left: 30px;

    li {
      padding-left: 20px;
      margin-bottom: 8px;
    }
  }

  a:not(.hk-btn-primary) {
    @include typography-body-regular;
    color: $secondary-color;
    text-decoration: none;
    word-wrap: break-word;
  }

  img {
    width: 100%;
  }

  details {
    border-bottom: 1px solid $neutral-light-color;
    padding: 12px 0;

    &:first-of-type {
      border-top: 1px solid $neutral-light-color;
    }

    &:last-of-type {
      margin-bottom: 20px;
    }

    summary {
      @include typography-body-regular;
      display: flex;
      align-items: center;
      position: relative;
      padding-right: 36px;
      list-style: none;
      min-height: 30px;

      &::webkit-details-marker {
        display: none;
      }

      &::after,
      &::before {
        content: '';
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        position: absolute;
        left: unset;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.2s ease-in-out;
      }
      &::before {
        content: '';
        background-color: $neutral-light-color;
      }
      &::after {
        content: '\eab8';
        font: normal normal normal 1em/1 'lineicons';
        font-size: 16px;
      }
    }

    &[open] {
      summary {
        &::before {
          background-color: $primary-color;
        }
        &::after {
          color: $white-color;
          transform: translateY(-50%) rotate(45deg);
        }
      }
      p {
        @include typography-body-regular;
        margin: 0;
        margin-top: 16px;
        margin-bottom: 16px;
      }
    }

    @media screen and (min-width: 768px) {
      padding: 15px 0;

      &:last-of-type {
        margin-bottom: 32px;
      }
    }
  }
}

.post-details {
  .table-wrapper {
    width: 100%;
    overflow-x: auto;
  }
  table {
    margin-bottom: 2rem;
    background-color: transparent;
  }

  colgroup.hk-colgroup {
    border: 1px solid $primary;
    background-color: $neutral-light-color;
    border-bottom-width: 2px;
  }

  tr {
    border-bottom: 1px solid $light-gray-color;
  }

  td,
  th {
    @include typography-body-regular;
    width: 206px;
    vertical-align: middle;
    padding: 22px 32px;
    text-align: center;
  }

  thead th {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding: 13px 32px;
  }
  tbody th {
    text-align: left;
  }
}
