.glossary-list-container {
  .hk-glossary-alphabates {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 17px;
    padding: 10px 40px;
    margin-top: 60px;
    margin-bottom: 60px;

    a {
      @include typography-body-regular;
      @include typography-semibold;
      color: $secondary-color;

      &:hover {
        text-decoration: underline;
      }

      @media screen and (min-width: 1200px) {
        @include typography-heading-h4;
      }
    }
  }

  .hk-glossary-cards {
    display: grid;
    gap: 60px;

    .hk-glossary-card {
      .box-title {
        @include typography-heading-h4;
        color: $primary-color;
        margin-bottom: 20px;
        margin-top: -120px;
        padding-top: 120px;
      }

      .hk-glossaries {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        margin: 0;
      }
    }
  }
}
