.blog-list-page {
  hyphens: auto;

  .hk-hero-section {
    padding-top: calc(12px + $main-nav-height-sm);
  }

  #search-box {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    margin-bottom: 12px;

    label {
      cursor: pointer;
    }

    #search-input {
      @include typography-body-regular;
      color: $neutral-darkest-color;
      outline: none;
      border: none;
      background: transparent;
      text-align: right;

      &:focus {
        &::placeholder {
          color: transparent;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    .hk-hero-section {
      padding-top: calc(24px + $main-nav-height-lg);
    }

    #search-box {
      margin-bottom: 24px;
    }
  }
}

.hk-post-card {
  --bs-border-radius: 2px;
  border: none;
  height: 100%;
  display: block;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    .hk-post-card__read-btn .arrow {
      transform: translateX(5px);
    }
  }

  .card-img-top {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }

  .card-body {
    padding: 32px 36px;
  }
}

.hk-post-card__category {
  @include typography-body-regular;
  @include typography-semibold;

  color: $secondary-color;
  margin-bottom: 16px;
  text-transform: uppercase;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.hk-post-card__title {
  @include typography-heading-h5;

  color: $primary-color;
  margin-bottom: 12px;
  hyphens: auto;

  @media screen and (min-width: 768px) {
    @include typography-heading-h4;

    margin-bottom: 8px;
  }
}

.hk-post-card__excerpt {
  @include typography-body-regular;

  margin-bottom: 24px;
}

.hk-post-card__read-btn {
  @include typography-body-regular;

  color: $primary-color;
  display: inline-flex;
  align-items: center;
  gap: 3px;
  margin-bottom: 24px;
  text-decoration: none;

  .arrow {
    position: relative;
    transition: transform 0.2s ease-in-out;
  }
}
