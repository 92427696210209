.angebot-result {
  @media (min-width: 768px) {
    text-align: center;
  }
}

.angebot-result__logo {
  margin-bottom: 20px;
}

.angebot-result__title {
  color: $primary-color;
  @include typography-heading-h3;
  margin-bottom: 20px;

  @media screen and (min-width: 768px) {
    @include typography-heading-h2;
    margin-bottom: 12px;
  }
}

.angebot-result__description {
  @include typography-body-regular;
  margin-bottom: 20px;
}

.angebot-result__calc-title {
  @include typography-heading-h4;
  margin-bottom: 20px;
  text-align: left;
}

.angebot-result__calc-row {
  text-align: left;
  line-height: normal;
  margin-bottom: 12px;
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
  }
}

.angebot-result__calc-label {
  @include typography-body-regular;
}

.angebot-result__calc-value {
  @include typography-heading-h5;
  @media screen and (min-width: 768px) {
    @include typography-body-regular;
  }
}

.angebot-result__adnotation {
  @include typography-caption-regular;
  text-align: left;
  margin-bottom: 20px;
}

.angebot-result__large-gap {
  margin-bottom: 20px;

  @media screen and (min-width: 768px) {
    margin-bottom: 40px;
  }
}

.angebot-result__btn {
  max-width: 546px;
  margin: 0 auto;
}

.angebot-result__calc-row--strong {
  .angebot-result__calc-label,
  .angebot-result__calc-value {
    @include typography-semibold;
  }
}

.angebot-result__calc-row--padding {
  margin-bottom: 20px;
}
