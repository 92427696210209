#usercentrics-button {
  #uc-banner-centered {
    top: unset !important;
    bottom: 16px !important;
    border-radius: 2px !important;
    width: min(819px, 95%) !important;
    max-width: unset !important;
    transform: translateX(-50%) !important;

    & * {
      font-family: 'Poppins', system-ui, sans-serif !important;
    }

    .uc-banner-content {
      background-color: $primary-color !important;
      padding: 57px 30px 25px;

      .uc-banner-text {
        color: $white-color !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 21px !important;
        margin: 0 !important;
      }

      &:after {
        content: 'Cookies und Datenschutz';
        font-size: 18px;
        line-height: 36px;
        font-weight: 400;
        position: absolute;
        top: 12px;
        left: 30px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: calc(100% - 45px);
      }

      @media screen and (min-width: 375px) {
        padding-top: 57px;
        &::after {
          font-size: 24px;
        }
      }
      @media screen and (min-width: 768px) {
        padding-top: 75px;
        &::after {
          top: 26px;
        }
      }
    }

    .uc-btn-new {
      font-size: 14px !important;
      line-height: 21px !important;
      border-radius: 2px !important;
      padding: 16px;
      color: $white-color !important;
      background-color: $neutral-dark-color !important;
    }

    .uc-btn-accept {
      background-color: $white-color !important;
      color: $primary-color !important;
    }

    .uc-banner-btn-list {
      display: flex !important;
      flex-direction: column;
      gap: 20px !important;

      & > div {
        grid-area: cookie-btn;
        margin: 0 !important;
      }

      @media screen and (min-width: 768px) {
        flex-direction: row;
      }
    }

    .uc-banner-links a,
    .uc-banner-links-separator {
      color: $white-color !important;
      font-size: 12px !important;
      line-height: 18px !important;
      text-decoration: underline !important;
      text-underline-offset: 2px;
    }

    @media screen and (min-width: 768px) {
      .uc-banner-links {
        display: none !important;
      }
    }
  }
}

.uc-overlay {
  background-color: $secondary-color !important;
  opacity: 0.7;
}
