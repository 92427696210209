.hk-accordion-card {
  background-color: $white-color;
  border-radius: 2px;
  padding: 11px 68px 11px 24px;
  position: relative;
  height: auto;

  .hk-accordion-header {
    .title {
      @include typography-heading-h6;
      color: $primary-color;
    }

    .toggler {
      background-color: $primary-color;
      border-radius: 1px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      position: absolute;
      top: 9px;
      right: 10px;

      .icon {
        color: $neutral-light-color;
        font-size: 16px;
        transform: rotate(45deg);
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .hk-accordion-body {
    @include typography-body-regular;
    margin: 0;
    margin-top: 7px;
    height: fit-content;
  }

  &.collapsed {
    .hk-accordion-header {
      .toggler {
        background-color: $neutral-light-color;

        .icon {
          color: $primary-color;
          transform: rotate(0deg);
        }

        &:hover {
          background-color: $primary-color;
          .icon {
            color: $neutral-light-color;
          }
        }
      }
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
}
