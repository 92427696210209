/* AWARDS AND CERTIFICATES SECTION */
.hk-awards-certificates-section {
  .hk-section-title {
    margin-bottom: 20px;
  }
  .hk-section-subtitle {
    width: min(100%, 966px);
    margin-left: auto;
    margin-right: auto;
  }

  .hk-awards-certificates {
    margin-bottom: 65px;
    height: 164px;

    a {
      color: $dark-black-color;
      text-decoration: none;
      &:hover {
        color: $neutral-dark-color;
      }
    }

    figure {
      background-color: $neutral-lightest-color;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      position: relative;
      padding: 20px;

      img {
        display: block;
        width: 90%;
        max-width: unset;
        max-height: 110px;
        object-fit: contain;
      }

      figurecaption {
        @include typography-caption-regular;
        line-height: 18px;
        position: absolute;
        top: calc(100% + 7px);
      }
    }
  }

  @media screen and (min-width: 576px) {
    .hk-section-subtitle {
      text-align: justify;
    }

    .hk-awards-certificates {
      margin-bottom: 75px;

      a {
        width: calc(20% - 15px);
      }

      figure {
        img {
          max-width: min(227px, 80%);
          max-height: 150px;
        }
        figurecaption {
          @include typography-body-regular;
          top: calc(100% + 20px);
        }
      }
    }
  }
}

.hk-awards-certificates-section.small {
  .hk-section-subtitle {
    margin-bottom: 20px;
  }
  .hk-awards-certificates {
    height: 164px;
    figure {
      img {
        width: 90%;
        max-width: unset;
        max-height: 110px;
        object-fit: contain;
      }
    }
  }

  @media screen and (min-width: 576px) {
    .hk-awards-certificates {
      figure {
        img {
          min-width: min(98px, 80%);
          max-height: 90px;
          object-fit: contain;
        }
      }
    }
  }

  @media screen and (min-width: 992px) {
    .hk-section-subtitle {
      margin-bottom: 40px;
      text-align: center;
    }

    .hk-awards-certificates {
      display: flex;
      gap: 15px;
      justify-content: space-between;
      align-items: center;

      figure {
        height: 115px;
        width: 100%;
      }
    }
  }
}
