@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');

@import './partials';
@import './pages';

.pagination {
  --bs-pagination-color: #2573c0;
  --bs-pagination-active-bg: #2573c0;
}

@media screen and (min-width: 1400px) {
  .container {
    max-width: 1140px;
  }
}

// @media screen and (max-width: 576px) {
//   * {
//     hyphens: auto;
//   }

//   table * {
//     hyphens: none;
//   }
// }
